import React from "react";
import { DebugModeLog, runAbTest } from "../utils";

import type {
  DefaultTracking,
  TrackingProviderProps,
} from "./TrackingContext.types";

const defaultTracking: DefaultTracking = {
  utm_source: "nosource",
  utm_source2: "",
  utm_campaign: "",
  utm_medium: "",
  utm_content: "",
  utm_term: "",
  utm_cost: "",
  abtest: "",
};

const TrackingContext = React.createContext(defaultTracking);

class TrackingProvider extends React.Component<TrackingProviderProps> {
  tracking: DefaultTracking = {
    utm_source: "nosource",
    utm_source2: "",
    utm_campaign: "",
    utm_medium: "",
    utm_content: "",
    utm_term: "",
    utm_cost: "",
    abtest: "",
  };
  abtest = "";

  constructor(props: TrackingProviderProps) {
    super(props);

    this.abtest = "";

    this.setAbtest();
    this.checkLocalStorage();
    this.setTracking();
  }

  getCookie(cname: string) {
    // TODO check if we can reuse the one in HeadScripts
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setAbtest() {
    let abtestVal;

    const params = new URLSearchParams(window.location.search);
    const abtestCookie = this.getCookie("abtest");
    if (params.get("abtest")) {
      abtestVal = params.get("abtest");
    } else if (abtestCookie) {
      abtestVal = abtestCookie;
    } else {
      abtestVal = runAbTest(0.24, 0.49, 0.5);
    }

    this.abtest = abtestVal ?? "";

    DebugModeLog(abtestVal ?? "");
  }

  setTracking() {
    const params = new URLSearchParams(window.location.search);

    this.tracking = {
      utm_source: params.get("utm_source") ?? "nosource",
      utm_source2: params.get("utm_source2") ?? "nosource",
      utm_campaign:
        params.get("utm_campaign") ??
        localStorage.getItem("utm_campaign") ??
        "",
      utm_medium:
        params.get("utm_medium") ?? localStorage.getItem("utm_medium") ?? "",
      utm_content:
        params.get("utm_content") ?? localStorage.getItem("utm_content") ?? "",
      utm_term:
        params.get("utm_term") ?? localStorage.getItem("utm_term") ?? "",
      utm_cost:
        params.get("utm_cost") ?? localStorage.getItem("utm_cost") ?? "",
      abtest: this.abtest,
    };

    /* Check Local storage for utm_source(s) */
    const localStorageUTMSource = localStorage.getItem("utm_source");
    if (!params.get("utm_source") && localStorageUTMSource) {
      this.tracking.utm_source = localStorageUTMSource;
    }
    const localStorageUTMSourceTwo = localStorage.getItem("utm_source2");
    if (!params.get("utm_source2") && localStorageUTMSourceTwo) {
      this.tracking.utm_source2 = localStorageUTMSourceTwo;
    }

    for (const key in this.tracking) {
      localStorage.setItem(
        key,
        String(this.tracking[key as keyof DefaultTracking])
      );
    }
  }

  checkLocalStorage() {
    if (window.location.search) return;

    for (const key in this.tracking) {
      if (localStorage.getItem(key))
        this.tracking[key as keyof DefaultTracking] =
          localStorage.getItem(key) ?? "";
    }
  }

  render() {
    const { children } = this.props;
    const {
      utm_source,
      utm_source2,
      utm_campaign,
      utm_medium,
      utm_content,
      utm_term,
      utm_cost,
      abtest,
    } = this.tracking;

    return (
      <TrackingContext.Provider
        value={{
          utm_source,
          utm_source2,
          utm_campaign,
          utm_medium,
          utm_content,
          utm_term,
          utm_cost,
          abtest,
        }}
      >
        {children}
      </TrackingContext.Provider>
    );
  }
}
export default TrackingContext;
export { TrackingProvider };
